import PrimaryButton from '@/components/atoms/Button/PrimaryButton'
import ExpandIcon from '@/components/atoms/Icon/svg/ExpandIcon'
import SelectField, { MenuItemProps } from '@/components/atoms/SelectField'
import AutocompleteSearchSite from '@/components/template/Analysis/Overview/AutoCompleteSearchSite'
import SiteSelector from '@/components/template/Analysis/Selector/SiteSelector'
import useCustomMediaQuery from '@/hooks/useCustomMediaQuery'
import theme from '@/theme'
import useStore from '@/zustand/sotre'
import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useRef, useState, useMemo, SetStateAction } from 'react'

const useStyle = makeStyles({
    container: {
        width: '100%',
        padding: '13px 15px',
        backgroundColor: theme.colors.expandBackground,
    },
    containerAnalysis: {
        backgroundColor: theme.colors.primaryN,
    },
    target: {
        width: '100%',
        marginLeft: 10,
        fontWeight: 300,
        fontSize: 16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    targetActive: {
        marginLeft: 0,
        marginTop: 10,
    },
    selectWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        columnGap: 10,
    },
    expandBtn: {},
    flex: { width: '100%', display: 'flex', alignItems: 'center', columnGap: 10 },
})

interface TSelectorGroup {
    isDashboard?: boolean
    year?: number
    description?: string
    isAnalysis?: boolean
    onPdfClick?: () => void
    value?: number
    onChange?: (
        e: React.ChangeEvent<{
            name?: string | undefined
            value: unknown
        }>,
    ) => void
    options?: MenuItemProps[]
    setSelectSite?: React.Dispatch<SetStateAction<MenuItemProps | null>>
    setYearSelectorHeight?: React.Dispatch<SetStateAction<number>>
}

function SelectorGroup(props: TSelectorGroup) {
    const { storeState } = useStore()
    const [showExpand, setShowExpand] = useState(false)
    const [showMore, setShowMore] = useState(false)
    const { matches1600 } = useCustomMediaQuery()
    const classes = useStyle()
    const ref = useRef<HTMLDivElement | null>(null)
    const descRef = useRef(null)
    const descInnerRef = useRef(null)
    const siteNamesOptions =
        useMemo(() => {
            const sites = storeState.sites?.filter((s) => props.isAnalysis || s.status == 1).map((s) => ({ id: s.id, value: s.name }))
            return sites
        }, [storeState.sites]) || []

    useEffect(() => {
        setShowExpand(true)
    }, [])

    useEffect(() => {
        if (ref.current?.offsetHeight && props.setYearSelectorHeight)
            props.setYearSelectorHeight(ref.current.offsetHeight)
    }, [showMore])

    const handleClickObjective = () => setShowMore((pre) => !pre)

    const generateDesc = () => (
        <div
            className={`${classes.target} ${matches1600 ? classes.targetActive : ''}`}
            ref={descRef}
            // id="desc"
        >
            <div
                ref={descInnerRef}
                className="text-clame-1"
                // id="desc_inner"
                style={{
                    display: showMore ? 'inline-block' : '-webkit-box',
                    cursor: 'default',
                }}
                onClick={handleClickObjective}
            >
                {props.year} 年度 目標：
                {props.description}
            </div>
            {showExpand ? (
                <IconButton onClick={handleClickObjective} className={classes.expandBtn}>
                    <ExpandIcon direction={showMore ? 'up' : 'down'} />
                </IconButton>
            ) : null}
        </div>
    )
    return (
        <div ref={ref} className={`${classes.container} ${props.isAnalysis ? classes.containerAnalysis : ''}`}>
            <div className={classes.selectWrapper}>
                <div className={classes.flex}>
                    <div style={{ minWidth: 320 }}>
                        {props.options && props.onChange ? (
                            <SelectField
                                size="sm"
                                fullWidth={true}
                                value={props.value}
                                onChange={props.onChange}
                                menuItems={props.options}
                            />
                        ) : (
                            <SiteSelector
                                isAnalysis={props.isAnalysis} />
                        )}
                    </div>
                    <AutocompleteSearchSite
                        setSelectSite={props.setSelectSite}
                        options={props.options || siteNamesOptions || []}
                    />
                    {props.isDashboard && !matches1600 && generateDesc()}
                </div>

                {props.isDashboard && (
                    <div style={{ width: 112 }}>
                        <PrimaryButton
                            width={112}
                            height={35}
                            fontSize={14}
                            onClick={() => {
                                if (props.onPdfClick) props.onPdfClick()
                            }}
                        >
                            PDF表示
                        </PrimaryButton>
                    </div>
                )}
            </div>
            {props.isDashboard && matches1600 && generateDesc()}
        </div>
    )
}

export default SelectorGroup
