import SearchIcon from '@/components/atoms/Icon/svg/SearchIcon'
import InputField from '@/components/atoms/InputField'
import { MenuItemProps } from '@/components/atoms/SelectField'
import { ALL_SITES } from '@/constant/sites'
import { SessionStorageKey, useStateWithSessionStorage } from '@/hooks/useStateWithStorage'
import { Site } from '@/openapi'
import useStore from '@/zustand/sotre'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React from 'react'

const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        input: {
            width: 320,
            background: theme.colors.white,
            borderRadius: 4,
        },
        popupIndicatorOpen: {
            transform: 'rotate(0deg)',
        },
        popper: {
            zIndex: 3,
        },
    }),
)

function AutocompleteSearchSite(props: {
    setSelectSite?: React.Dispatch<React.SetStateAction<MenuItemProps | null>>
    options: MenuItemProps[]
}) {
    const { storeState, setSelectedSite, analysisState, setAnalysisState } = useStore()
    const [_, setSiteSessionStorage] = useStateWithSessionStorage<Site | null>(SessionStorageKey.SITE_SELECTOR, null)
    const classes = useStyle()

    const handleChange = (id: number) => {
        const selectedSite = storeState.sites.find((s) => s.id === id)
        if (selectedSite) {
            props.setSelectSite && props.setSelectSite({ id: selectedSite.id, value: selectedSite.name })
            setAnalysisState({
                ...analysisState,
                selectedSite,
            })
            setSelectedSite(selectedSite)
            setSiteSessionStorage(selectedSite)
        }
        if (id === 0) {
            props.setSelectSite && props.setSelectSite(ALL_SITES)
        }
    }

    return (
        <>
            <Autocomplete
                classes={{ popupIndicatorOpen: classes.popupIndicatorOpen, popper: classes.popper }}
                options={props.options}
                getOptionLabel={(option) => option.value}
                popupIcon={<SearchIcon />}
                closeIcon={false}
                noOptionsText="選択なし"
                onChange={(e, value, _) => value && handleChange(value?.id)}
                renderInput={(params) => (
                    <InputField {...params} size="small" className={classes.input} placeholder={'拠点名で検索'} />
                )}
            />
        </>
    )
}

export default AutocompleteSearchSite
