import SelectField from '@/components/atoms/SelectField'
import { SessionStorageKey, useStateWithSessionStorage } from '@/hooks/useStateWithStorage'
import { Site } from '@/openapi'
import useStore from '@/zustand/sotre'
import React, { useMemo } from 'react'

const SiteSelector = (
    props: {
        isAnalysis?: boolean
    }
) => {
    const { storeState, setSelectedSite, analysisState, setAnalysisState } = useStore()
    const [_, setSiteSessionStorage] = useStateWithSessionStorage<Site | null>(SessionStorageKey.SITE_SELECTOR, null)
    const siteNamesOptions =
        useMemo(() => {
            const sites = storeState.sites?.filter((s) => props.isAnalysis || s.status == 1).map((s) => ({ id: s.id, value: s.name }))
            return sites
        }, [storeState.sites]) || []

    const handleSelectSite = function (id: number) {
        const site = siteNamesOptions.find((s) => s.id === id)
        if (site && storeState.organization) {
            const selectedSite: Site = {
                id: site.id,
                name: site.value,
                organizationId: storeState.organization?.id,
                status: storeState.organization.status,
            }
            setAnalysisState({
                ...analysisState,
                selectedSite,
            })
            setSelectedSite(selectedSite)
            setSiteSessionStorage(selectedSite)
        }
    }

    return (
        <>
            {siteNamesOptions && (
                <SelectField
                    size="sm"
                    fullWidth={true}
                    value={storeState.selectedSite?.id || ''}
                    onChange={(e) => handleSelectSite(e.target.value as number)}
                    menuItems={siteNamesOptions}
                />
            )}
        </>
    )
}

export default SiteSelector
